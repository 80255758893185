<script setup>
import { onMounted, computed } from 'vue'
import router from '../router'
import lottie from 'lottie-web'
import { navbarStore } from '@/stores/navbar'

const navbar = navbarStore()

const getName = computed(() => (navbar && navbar.user ? navbar.user.split(' ')[0] : ''))

onMounted(() => {
  if(!localStorage.getItem('userId')) {
    // user non logged in
    router.push({ path: '/login' })
  }

  lottie.loadAnimation({
    container: document.getElementById('lottie'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: './man-at-work.json'
  })
})
</script>

<template>
  <div class="welcome rainbowText">
    <h1>Ciao {{ getName }},</h1>
    <h2>benvenuto/a nel backoffice di CVing!</h2>
    <div id="lottie" />
    <h4>Qui potrai svolgere tutte le attività che contribuiscono alla gestione operativa e al supporto del lavoro in azienda.</h4>
    <h2>Grazie!</h2>
  </div>
</template>

<style lang="scss" scoped>
.welcome {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 25px 50px;
  color: white;
  align-items: center;
  height: 90%;
   @media (min-width: 900px) {
     height: unset;
    }

}
#lottie {
    margin:0;
    max-width: 50vw;
    margin-top: -50px;
    @media (min-width: 900px) {
      max-width: 40vw;
      margin: -40px 0 -30px 0;
    }
}

h1, h2 {
   padding-bottom: 20px;
    @media (min-width: 900px) {
     padding-bottom: 0px;
    }
}
h4 {
   @media (min-width: 900px) {
    max-width: 50vw;
  }
}

  h1 {
    font-size: 2em;
    font-weight: 400;
    padding-bottom: 5px;
    //color: rgb(208, 233, 249);
  }
  h2 {
    font-size: 1.4em;
    font-weight: 100;
    //color: rgb(208, 233, 249);
  }
  h4 {
    font-size: 1.25em;
    font-weight: 100;
    padding-bottom: 10px;
    text-align: center;
    // color: rgb(208, 233, 249);
  }
h1,h2,h4 {
  color: #cdfaf9;
  text-align: center;
}

</style>
